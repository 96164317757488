
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { DropUpName } from '@/definitions/_layout/dropUps/types'
import { useDropUps } from '@/compositions/dropups'

export default defineComponent({
  name: 'ComposePreviewModal',
  components: { TmButton, TmModal },
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
    dropUpName: {
      type: String as PropType<DropUpName>,
      required: true,
    },
  },
  setup(props) {
    const { closeDropUp } = useDropUps()
    const submit = (func: () => void) => {
      func()
      closeDropUp(props.dropUpName)
    }

    return {
      submit,
    }
  },
})
